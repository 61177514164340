<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <q-form ref="editForm">
        <c-table
          ref="grid"
          title="지도ㆍ점검을 받은 사항"
          tableId="grid"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="grid.data"
          :usePaging="false"
          :hideBottom="false"
          :columnSetting="false"
          :filtering="false"
          :isExcelDown="false"
          :editable="editable"
          :gridHeightAuto="param.isFullScreen"
          selection="multiple"
          rowKey="envWaterDailyResultGuidanceId"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="!param.isFullScreen && editable" label="추가" icon="add" @btnClicked="addRow" />
              <c-btn v-if="!param.isFullScreen && editable" label="삭제" icon="remove" @btnClicked="deleteRow" />
              <c-btn label="저장" icon="save" v-if="!param.isFullScreen && editable" @btnClicked="saveData" />
            </q-btn-group>
          </template>
        </c-table>
      </q-form>
    </div>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'daily-report-11',
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: null,
        envAirDailyResultId: '',
        isFullScreen: false,
        dailyResultDt: '',
        approvalStatusCd: null,
        approvalTarget: false,
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'guidanceOrgName',
            field: 'guidanceOrgName',
            label: '단속기관',
            align: 'center',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'guidanceUserName',
            field: 'guidanceUserName',
            label: '단속기관 점검자',
            align: 'center',
            type: 'text',
            style: 'width:200px',
            sortable: false,
          },
          { 
            required: true,
            name: 'violationItem',
            field: 'violationItem',
            label: '위반사항',
            align: 'left',
            style: 'width:300px',
            type: 'textarea',
            sortable: false,
          },
          {
            required: true,
            name: 'actionItem',
            field: 'actionItem',
            label: '조치사항',
            align: 'left',
            type: 'textarea',
            sortable: false,
          },
        ],
        data: [],
        height: '600px',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    };
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  computed: {
    editable() {
      if (this.param.approvalTarget) {
        return true;
      } else if (this.param.approvalStatusCd !== null) {
        return false;
      } else {
        return this.$route.meta.editable
      }
    },
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.env.water.daily.guidance.list.url;
      this.saveUrl = transactionConfig.env.water.daily.guidance.save.url;
      this.deleteUrl = transactionConfig.env.water.daily.guidance.delete.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {envWaterDailyResultId: this.param.envWaterDailyResultId, plantCd: this.param.plantCd};
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    addRow() {
      this.grid.data.push(
        {
          envWaterDailyResultId: this.param.envWaterDailyResultId,  // 수질일지 일련번호
          envWaterDailyResultGuidanceId: uid(),  // 지도점검일련번호
          guidanceOrgName: '',  // 단속기관
          guidanceUserName: '',  // 단속기관점검자
          violationItem: '',  // 위반사항
          actionItem: '',  // 조치사항
          editFlag: 'C',
        }
      )
    },
    deleteRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내',
          message: '선택된 항목이 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '삭제하시겠습니까?',
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveData() {
      let saveData = this.grid.data.filter( x => {
        return x.editFlag == 'U' || x.editFlag == 'C'
      });
      this.$_.forEach(saveData, item => {
        item.regUserId = this.$store.getters.user.userId;
        item.chgUserId = this.$store.getters.user.userId;
      });
      if(saveData.length > 0) {
        this.$refs['editForm'].validate().then(_result => {
          if(_result && this.$comm.validTable(this.grid.columns, this.grid.data)) {
            window.getApp.$emit('CONFIRM', {
              title: '확인',
              message: '저장하시겠습니까?',
              type: 'info',
              // 확인 callback 함수
              confirmCallback: () => {
                this.$http.url = this.saveUrl;
                this.$http.type = 'POST';
                this.$http.param = saveData;
                this.$http.request(() => {
                  this.getList();
                  window.getApp.$emit('ALERT', {
                    title: '안내', 
                    message: '저장되었습니다.', 
                    type: 'success', // success / info / warning / error
                  });
                });
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          }
        });
      } else {
        window.getApp.$emit('ALERT', {
          title: '안내', 
          message: '변경된 데이터가 없습니다.',
          type: 'info', // success / info / warning / error
        });
      }
    },
  }
};
</script>
